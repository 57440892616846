@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  outline: none !important;
}

html,
body,
#root,
.App {
  width: 100%;
  scroll-behavior: smooth;
  height: 100vh;
}

::-webkit-scrollbar {
  margin-top: 200px;
  padding-top: 200px;

  background: none;
  width: 0.375rem;
  height: 0.375rem;
}
::-webkit-scrollbar-track {
  margin-top: 20px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #d0d5dd;
}

/* styles.css */
.border-gradient-dashed {
  position: relative;
}

.border-gradient-dashed::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px dashed rgba(255, 255, 255, 0.6); /* Dashed overlay */
  border-radius: initial;
  pointer-events: none; /* Allows clicks to pass through */
  mask-image: radial-gradient(
    73.06% 160316.53% at 47.86% 29300%,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 0) 80%
  );
}

@keyframes fallDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes moveGreenCursor {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(24px, 0);
  }
  50% {
    transform: translate(24px, 24px);
  }
  75% {
    transform: translate(24px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes moveOrangeCursor {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(20px, -20px);
  }
  50% {
    transform: translate(40px, 0);
  }
  75% {
    transform: translate(20px, 20px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes moveRedCursor {
  0% {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(-30px, 0);
  }
  66% {
    transform: translate(-60px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes moveBlueCursor {
  0% {
    transform: translate(0, 0);
  }
  60% {
    transform: translate(-20px, -20px);
  }
  100% {
    transform: translate(0, 0);
  }
}
